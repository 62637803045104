<template>
    <table
        cellpadding="0"
        cellspacing="0"
        width="100%"
        :style="{
            width: '100%',
        }"
        valign="middle"
    >
        <tbody
            :style="{
                width: '100%',
                backgroundColor: '#fff',
            }"
        >
            <template
                v-if="
                    attrs.layout === 'grid' ||
                    (componentData &&
                        componentData.parentData &&
                        componentData.parentData.name === 'NewsPostColumn')
                "
            >
                <tr
                    v-for="(row, i) in chunk(
                        filteredNewsPosts,
                        componentData &&
                            componentData.parentData &&
                            componentData.parentData.name === 'NewsPostColumn'
                            ? 1
                            : 2,
                    )"
                    :key="i"
                    :style="{
                        width: '100%',
                        borderBottom: '16px solid #fff',
                    }"
                >
                    <td
                        v-for="(np, npi) in row"
                        :key="npi"
                        align="center"
                        valign="top"
                        style="width: 300px"
                        class="news-post-column-container"
                    >
                        <Spacer></Spacer>
                        <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="width: 300px"
                        >
                            <tr>
                                <td>
                                    <img
                                        :src="
                                            np.metadata.props.thumbnail
                                                ? np.metadata.props.thumbnail
                                                      .url
                                                : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg'
                                        "
                                        alt=""
                                        width="300"
                                        height="175"
                                    />
                                    <p
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                    >
                                        {{ np.metadata.props.title }}
                                    </p>
                                    <p
                                        v-if="
                                            attrs.show_date &&
                                            np.custom_date &&
                                            np.published_at
                                        "
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                        class="ca-contents-content-date"
                                    >
                                        {{
                                            $moment(
                                                new Date(np.custom_date) ||
                                                    new Date(np.published_at),
                                            ).format('D MMMM, YYYY')
                                        }}
                                    </p>
                                    <p
                                        v-if="np.description"
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                        class="description ca-contents-content-excerpt text-truncate-description"
                                    >
                                        <span v-html="np.description"></span>
                                    </p>

                                    <table cellpadding="0" cellspacing="0">
                                        <tbody>
                                            <tr>
                                                <ButtonLink
                                                    :component-data="{
                                                        value: 'Read more',
                                                        props: {
                                                            block: false,
                                                            href:
                                                                np.published_static_pdf_url ||
                                                                np.draft_static_pdf_url,
                                                            text: '#fff',
                                                            bg_color:
                                                                'brand_secondary',
                                                        },
                                                    }"
                                                ></ButtonLink>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <Spacer></Spacer>
                    </td>
                </tr>
            </template>
            <template v-else>
                <tr
                    v-for="(np, i) in filteredNewsPosts"
                    :key="i"
                    :style="{
                        width: '100%',
                        borderBottom: '16px solid #fff',
                    }"
                >
                    <td
                        align="center"
                        valign="top"
                        style="width: 300px"
                        class="news-post-column-container"
                    >
                        <Spacer></Spacer>
                        <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="width: 300px"
                        >
                            <tr>
                                <td align="center" valign="center">
                                    <img
                                        :src="
                                            np.metadata.props.thumbnail
                                                ? np.metadata.props.thumbnail
                                                      .url
                                                : 'https://ca-v2.s3-ap-southeast-2.amazonaws.com/placeholder.jpg'
                                        "
                                        alt=""
                                        width="300"
                                        height="175"
                                    />
                                </td>
                            </tr>
                        </table>
                        <Spacer></Spacer>
                    </td>
                    <td
                        align="center"
                        valign="top"
                        style="width: 300px"
                        class="news-post-column-container"
                    >
                        <Spacer></Spacer>
                        <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="width: 300px"
                        >
                            <tr>
                                <td>
                                    <p
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                    >
                                        {{ np.metadata.props.title }}
                                    </p>
                                    <p
                                        v-if="
                                            attrs.show_date &&
                                            np.custom_date &&
                                            np.published_at
                                        "
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                        class="ca-contents-content-date"
                                    >
                                        {{
                                            $moment(
                                                new Date(np.custom_date) ||
                                                    new Date(np.published_at),
                                            ).format('D MMMM, YYYY')
                                        }}
                                    </p>
                                    <p
                                        v-if="np.description"
                                        :style="{
                                            fontFamily:
                                                projectConfig.fontFamily,
                                        }"
                                        class="description ca-contents-content-excerpt text-truncate-description"
                                    >
                                        <span v-html="np.description"></span>
                                    </p>

                                    <table cellpadding="0" cellspacing="0">
                                        <tbody>
                                            <tr>
                                                <ButtonLink
                                                    :component-data="{
                                                        value: 'Read more',
                                                        props: {
                                                            block: false,
                                                            href:
                                                                np.published_static_pdf_url ||
                                                                np.draft_static_pdf_url,
                                                            text: '#fff',
                                                            bg_color:
                                                                'brand_secondary',
                                                        },
                                                    }"
                                                ></ButtonLink>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Spacer></Spacer>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
import { chunk } from 'lodash-es';
import ButtonLink from './ButtonLink';
import Spacer from './Spacer.vue';
import componentInlineStyle from '~/utils/componentInlineStyle';
export default {
    name: 'NewsPosts',
    components: {
        Spacer,
        ButtonLink,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            newsPosts: [],
        };
    },
    async fetch() {
        await this.getNewsPosts();
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            const classList = [
                this.attrs.element_class || '',
                this.attrs.layout,
            ];
            if (this.attrs.layout === 'grid') {
                classList.push('ca-cards-grid');
            }
            return classList;
        },
        filteredNewsPosts() {
            const showDrafts = this.attrs.filter_by_status === 'All';
            const allPublishedIDs = this.newsPosts
                .filter((w) => w.published_status === 'published')
                .map((w) => w.metadata.uuid);

            const filtered = this.newsPosts
                .filter((wa) => {
                    if (wa.published_status.toLowerCase() !== 'published') {
                        if (
                            !showDrafts ||
                            allPublishedIDs.includes(wa.metadata.uuid)
                        ) {
                            return false;
                        }
                    }

                    return true;
                })
                .map((np) => {
                    this.newsPosts.find(
                        (unfilteredNp) => unfilteredNp.uuid === np.uuid,
                    );

                    return {
                        ...np,
                        description:
                            np.metadata.props.description ||
                            this.findParagraphText(np.metadata),
                    };
                });

            return filtered.slice(0, this.limit(filtered));
        },
    },
    methods: {
        chunk,
        limit(contents) {
            if (this.attrs.show_number === 'custom') {
                const number = Number(this.attrs.show_number_custom);
                if (number && Number.isInteger(number) && number > 0) {
                    return number;
                } else {
                    return 3;
                }
            }
            return this.attrs.show_number === 'all'
                ? contents.length
                : this.attrs.show_number;
        },
        async getNewsPosts() {
            const vm = this;
            const newsPosts = await this.$store.dispatch(
                'api/getAllContentBasic',
                {
                    forEditor: false,
                    contentType: 'news_post',
                    category: vm.attrs.category_filter,
                    sort:
                        vm.attrs.sort_direction === 'ASC'
                            ? 'custom_date'
                            : '-custom_date',
                    includeChildProjects: vm.attrs.include_child_projects,
                },
                false,
            );
            vm.newsPosts = newsPosts ?? [];
        },
        findParagraphText(newsPost) {
            let paragraphText;

            newsPost.children?.forEach((child) => {
                if (child.name === 'Paragraph') {
                    paragraphText = child.value;
                } else {
                    this.findParagraphText(child);
                }
            });

            return paragraphText;
        },
    },
};
</script>
