import { Model } from 'vue-api-query';

export default async function ({ $axios, $config }) {
    if (process.server) {
        const { access_token: authToken } = await $axios.$post(
            '/auth/login',
            null,
            {
                params: {
                    email: $config.API_EMAIL,
                    password: $config.API_PASSWORD,
                },
            },
        );
        $axios.setToken(authToken, 'Bearer');
    }

    Model.$http = $axios;
}
