import { render, staticRenderFns } from "./NewsPostsItem.vue?vue&type=template&id=29075b84&scoped=true&"
import script from "./NewsPostsItem.vue?vue&type=script&lang=js&"
export * from "./NewsPostsItem.vue?vue&type=script&lang=js&"
import style0 from "./NewsPostsItem.vue?vue&type=style&index=0&id=29075b84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29075b84",
  null
  
)

export default component.exports