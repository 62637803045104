import { render, staticRenderFns } from "./FormSection.vue?vue&type=template&id=703bb608&scoped=true&"
import script from "./FormSection.vue?vue&type=script&lang=js&"
export * from "./FormSection.vue?vue&type=script&lang=js&"
import style0 from "./FormSection.vue?vue&type=style&index=0&id=703bb608&lang=scss&scoped=true&"
import style1 from "./FormSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703bb608",
  null
  
)

export default component.exports