<template>
    <b-card id="message">
        <h2>{{ subheading }}</h2>
        <h1>{{ heading }}</h1>
        <p>
            {{ description }}
        </p>
        <slot></slot>
    </b-card>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            default: () => {},
        },
        heading: { type: String, default: null },
        subheading: { type: [String, Number], default: null },
        description: { type: String, default: null },
    },
    head() {
        return {
            title: this.heading,
            meta: this.meta,
        };
    },
    computed: {
        meta() {
            return [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.description,
                },
            ].filter(({ content }) => content !== null);
        },
    },
};
</script>

<style media="screen" scoped>
#message {
    max-width: 360px;
    margin: 100px auto 16px;
    padding: 32px 24px 16px;
    border-radius: 3px;
}
#message h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 16px 0 12px;
}
#message h2 {
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
}
#message h1 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 16px;
}
#message p {
    line-height: 140%;
    margin: 16px 0 24px;
    font-size: 14px;
}
#load {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 13px;
}
</style>
