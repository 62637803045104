<template>
    <div class="news-posts-container" :style="style" @click="doClickAction">
        <div v-if="loading" class="text-center">
            <div class="mb-3">News Posts Loading...</div>
            <b-spinner
                variant="primary"
                label="Spinner"
                style="width: 150px; height: 150px"
            ></b-spinner>
        </div>
        <div v-else-if="error" class="text-center">
            Error loading News Posts
        </div>
        <div :id="elementID" :class="classList" class="news-posts ca-newsposts">
            <b-carousel
                v-if="attrs.layout === 'carousel'"
                :interval="attrs.auto_slide ? attrs.slide_timer : 0"
                :controls="attrs.pagination"
                :indicators="attrs.pagination"
                :fade="attrs.slide_effect === 'fade'"
                :no-animation="attrs.slide_effect === 'none'"
                class="ca-component mx-auto"
                style="width: 100%"
            >
                <NewsPostsItem
                    v-for="(np, i) in filteredNewsPosts"
                    :key="i"
                    :np="np"
                    :attrs="attrs"
                ></NewsPostsItem>
            </b-carousel>
            <template v-else>
                <NewsPostsItem
                    v-for="(np, i) in filteredNewsPosts"
                    :key="i"
                    :np="np"
                    :attrs="attrs"
                ></NewsPostsItem>
            </template>
        </div>
        <DragHandle />
        <Adder
            :component-data="componentData.parentData"
            :child-u-u-i-d="componentData.uuid"
        />
    </div>
</template>

<script>
import NewsPostsItem from './NewsPostsItem.vue';
import Adder from '~/components/editor/Adder';
import DragHandle from '~/components/editor/DragHandle';
import componentInlineStyle from '~/utils/componentInlineStyle';
export default {
    name: 'NewsPosts',
    components: {
        Adder,
        DragHandle,
        NewsPostsItem,
    },
    props: {
        componentData: Object,
    },
    data() {
        return {
            attrs: this.componentData.props || {},
            newsPosts: [],
            modal: false,
            loading: false,
            error: false,
        };
    },
    computed: {
        style() {
            return {
                ...componentInlineStyle(this.attrs, this.getColor),
            };
        },
        elementID() {
            return this.attrs.element_id || '';
        },
        classList() {
            const classList = [
                this.attrs.element_class || '',
                this.attrs.layout,
            ];
            if (this.attrs.layout === 'grid') {
                classList.push('ca-cards-grid');
            }
            if (this.attrs.layout === 'list') {
                classList.push('ca-cards-list');
            }
            if (this.attrs.pagination && this.attrs.layout === 'carousel') {
                classList.push('pagination');
            }
            return classList;
        },
        filteredNewsPosts() {
            const showDrafts = this.attrs.filter_by_status === 'All';
            const allPublishedIDs = this.newsPosts
                .filter((w) => w.published_status === 'published')
                .map((w) => w.metadata.uuid);

            const filtered = this.newsPosts
                .filter((wa) => {
                    if (wa.published_status.toLowerCase() !== 'published') {
                        if (
                            !showDrafts ||
                            allPublishedIDs.includes(wa.metadata.uuid)
                        ) {
                            return false;
                        }
                    }

                    return true;
                })
                .map((np) => {
                    this.newsPosts.find(
                        (unfilteredNp) => unfilteredNp.uuid === np.uuid,
                    );

                    return {
                        ...np,
                        description:
                            np.metadata.props.description ||
                            this.findParagraphText(np.metadata),
                    };
                });

            return filtered.slice(0, this.limit(filtered));
        },
    },
    watch: {
        attrs: {
            deep: true,
            handler() {
                this.getNewsPosts();
            },
        },
    },
    mounted() {
        this.getNewsPosts();
    },
    methods: {
        limit(contents) {
            if (this.attrs.show_number === 'custom') {
                const number = Number(this.attrs.show_number_custom);
                if (number && Number.isInteger(number) && number > 0) {
                    return number;
                } else {
                    return 3;
                }
            }
            return this.attrs.show_number === 'all'
                ? contents.length
                : this.attrs.show_number;
        },
        getNewsPosts() {
            const vm = this;
            vm.loading = true;
            this.$store
                .dispatch(
                    'api/getAllContentBasic',
                    {
                        forEditor: false,
                        contentType: 'news_post',
                        category: vm.attrs.category_filter,
                        sort:
                            vm.attrs.sort_direction === 'ASC'
                                ? 'custom_date'
                                : '-custom_date',
                        includeChildProjects: vm.attrs.include_child_projects,
                    },
                    false,
                )
                .then((newsPosts) => {
                    vm.error = false;
                    vm.newsPosts = newsPosts ?? [];
                })
                .catch((err) => {
                    vm.error = true;
                    console.error('Error retrieving news posts: ', err);
                })
                .finally(() => {
                    vm.loading = false;
                });
        },
        findParagraphText(newsPost) {
            let paragraphText;

            newsPost.children?.forEach((child) => {
                if (child.name === 'Paragraph') {
                    paragraphText = child.value;
                } else {
                    this.findParagraphText(child);
                }
            });

            return paragraphText;
        },
    },
};
</script>

<style lang="scss" scoped>
.news-posts {
    list-style-type: none;
    display: flex;

    &:hover {
        .drag-handle,
        .adder {
            opacity: 1;
        }
    }

    &.list {
        flex-direction: column;
        flex-wrap: nowrap;

        .news-post {
            display: flex;
            flex-direction: row;

            .thumbnail-container {
                border-radius: 4px 0 0 4px;
                width: 100%;
                padding-top: 22.5%;
            }

            .content {
                flex-basis: 60%;
            }
            .thumbnail-container {
                flex-basis: 40%;
            }
        }
    }

    &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        /* This is better for small screens, once min() is better supported */
        /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
        grid-gap: 1rem;

        .news-post {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            position: relative;
            height: 100%;

            .thumbnail-container {
                width: 100%;
                border-radius: 4px 0 0 4px;
            }

            .content {
                flex-basis: 100%;
            }
        }
    }

    .news-post {
        background: #fff;

        .thumbnail-container {
            background-size: cover;
            background-position: center center;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: auto;

            .buttons {
                padding-top: 0.5em;
                color: $light;
            }

            .description {
                display: flex;
                flex-direction: row;
            }

            h5 {
                font-weight: 700;
            }

            svg {
                width: 30px;
                text-align: center;
            }
        }
    }
}
</style>

<style lang="scss">
.news-post-content {
    .heading {
        background: $primary;
        padding: 1em;
        border-radius: 4px 0 0 0;

        h3 {
            color: $light;
            margin: 0;
        }
    }
}
</style>
