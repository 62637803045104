const onClickActions = {
    computed: {
        href() {
            const type = this.attrs.on_click;
            if (type === 'url' && this.attrs.href) {
                return this.attrs.href;
            } else if (type === 'download' && this.attrs.click_download?.url) {
                return this.attrs.click_download.url;
            } else if (
                (type === 'media' && this.attrs.click_media) ||
                (type === 'larger_new_tab' &&
                    this.attrs.media &&
                    this.attrs.media.ref)
            ) {
                const domain = this.$config.API_MEDIA_SERVICE_DOMAIN;
                const media = this.attrs.click_media ?? this.attrs.media;
                if (
                    ['document', 'video', 'before_after'].includes(media.type)
                ) {
                    return `https://${domain}/embed/${media.ref}`;
                } else {
                    return this.$img(media.ref, {
                        t: Date.now(),
                    });
                }
            } else if (
                ['modal', 'form', 'page'].includes(type) &&
                this.attrs[`click_${type}`]
            ) {
                const uuid = this.attrs[`click_${type}`];
                return `https://caportal.com.au/cms/${uuid}`;
            } else {
                return undefined;
            }
        },
        download() {
            return this.attrs.on_click === 'download' &&
                this.href &&
                !this.isPreview
                ? 'download'
                : undefined;
        },
        target() {
            return this.attrs.open_new_tab ? '_blank' : undefined;
        },
    },
};

export default onClickActions;
