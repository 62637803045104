import Vue from 'vue';
import slugify from 'slugify';
import _filterContent from '~/utils/filterContent';
import isUrlValid from '~/utils/isUrlValid';
const Diff = require('text-diff');

export const state = () => ({
    slugPath: '/',
    parentMethods: null,
    iframe: true,
    publishable: false,
    active: null,
    publishState: null, // saved, publishOptions, publishing, published
    serverError: null,
    editingModal: null,
    contentType: null,
    parentContent: null,
    remoteContent: null,
    diffs: [],
    selectingMedia: false,
    mediaError: false,
});

export const getters = {
    // Used for the editor's Save/Publish modal,
    // lets it know whether the modal should display
    // and what message/content it should show
    publishState(state) {
        return state.publishState;
    },
    serverError(state) {
        return state.serverError;
    },
};

export const mutations = {
    setSlugPath: (state, val) => {
        state.slugPath = val;
    },
    setParentContent: (state, val) => {
        state.parentContent = val;
    },
    setParentMethods: (state, data) => {
        state.parentMethods = data;
    },
    setIframe: (state, val) => {
        state.iframe = val;
    },
    sendContentUpdates: (state, data) => {
        state.parentMethods?.editorContentUpdate(data);
    },
    setPublishState: (state, val) => {
        state.publishState = val;
    },
    setServerError: (state, val) => {
        state.serverError = val;
    },
    setToPublishable: (state, val) => {
        state.publishable = val;
    },
    setActive(state, data) {
        state.active = JSON.parse(JSON.stringify(data));
    },
    setEditingModal(state, data) {
        state.editingModal = data;
    },
    setContentType(state, data) {
        state.contentType = data;
    },
    addDiff(state, data) {
        state.diffs.push(data);
    },
    setRemoteContent(state, data) {
        state.remoteContent = JSON.parse(JSON.stringify(data));
    },
    setSelectingMedia(state, val) {
        state.selectingMedia = val;
    },
    setMediaError(state, val) {
        state.mediaError = val;
    },
};

export const actions = {
    async init({ commit, dispatch, rootState }, contentType) {
        const vm = this;
        const notInIframe = window.location === window.parent.location;
        if (notInIframe) {
            commit('setIframe', false);
            if (
                !this.$config.STANDALONE &&
                !this.$router.path === '/news-post/view'
            ) {
                throw Vue.prototype.$penpal.ErrorCode.NotInIframe;
            }
            if (!this.$router.currentRoute.query.id) {
                throw new Error('No id provided');
            }
        }

        if (!rootState.api.authToken) {
            if (notInIframe && !this.$config.STANDALONE) {
                throw new Error('No auth token');
            } else {
                this.$router.push('/login');
            }
        }

        const connection = !notInIframe
            ? Vue.prototype.$penpal.connectToParent({
                  targetOrigin: vm.$config.BASE_CMS_URL,
                  methods: {
                      async editorSaveDraft() {
                          await dispatch('editorSaveDraft');
                      },
                      // Actual publishing happens in the api store module
                      editorPublish() {
                          return new Promise((resolve) => {
                              commit('setPublishState', 'publishOptions');
                              resolve();
                          });
                      },
                      editorOpenCampaign() {
                          return new Promise((resolve) => {
                              commit('setPublishState', 'distributionLists');
                              resolve();
                          });
                      },
                      editorOpenTestEmail() {
                          return new Promise((resolve) => {
                              commit('setPublishState', 'testEmail');
                              resolve();
                          });
                      },
                  },
              })
            : null;

        const parentMethods = await connection?.promise;
        commit('setParentMethods', parentMethods);
        commit('penpal/setParentMethods', parentMethods, { root: true });
        commit('setPreview', true, { root: true });
        const penpalRes = await parentMethods?.editorGetToken();
        commit('penpal/setEcho', penpalRes?.echo, { root: true });

        /**
         * TODO
         * Can CMS penpal response add a config property, eg
         * ```js
         * {
         *    projectDetails: {
         *       config: {
         *          colors: {
         *            brand: '#143980',
         *            primary: '#00ff00',
         *            secondary: '#ff0000',
         *         },
         *         fontFamily: "'Montserrat', Arial, sans-serif"
         *      },
         *   },
         * },
         * ```
         *
         * Can also add logo, icons etc, but this will need
         * to be updated through the editor to use
         * the config (currently hardcoded)
         *
         * Once this is implemented, need to override the
         * hardcoded config in the api store module
         * so that fonts & colors through the app
         * are updated for the specific project
         */

        const config = {
            api: {
                url: vm.$config.BASE_API_URL,
            },
            user: {
                token: rootState.api.authToken,
            },
        };

        if (penpalRes?.authToken) {
            config.user.token = penpalRes?.authToken;
            commit('api/setAuthToken', penpalRes?.authToken, { root: true });
        }

        commit('api/setApiClient', config, { root: true });

        const accessToken = rootState.api.authToken;
        vm.$axios.setToken(accessToken, 'Bearer');
        const { data: user } = await vm.$axios.$get('/auth/user');
        commit('api/setUser', user, { root: true });
        vm.$sentry.setUser({ email: user.email });

        /**
         * TODO
         * Expect only contentUUID from CMS penpal data
         * and then update expression below
         */
        const contentUUID =
            this.$router.currentRoute.query.id ??
            penpalRes?.contentUUID ??
            penpalRes?.campaignTemplateUUID ??
            penpalRes?.pageTemplateUUID ??
            penpalRes?.workActivityUUID ??
            penpalRes?.pageUUID ??
            penpalRes?.headerUUID ??
            penpalRes?.newsPostUUID ??
            penpalRes?.modalUUID ??
            penpalRes?.mapUUID ??
            penpalRes?.widgetUUID ??
            penpalRes?.sceneUUID;
        const getURL = `/contents/${contentUUID}`;

        const apiRes = await vm.$axios.get(getURL);
        const contentData = apiRes.data.data;
        const projectID = contentData.project_id;
        const projectDetails =
            penpalRes?.projectDetails ??
            (await vm.$axios.$get(`/projects/${projectID}`))?.data;

        const client = await dispatch(
            'api/getClient',
            projectDetails.client_id,
            {
                root: true,
            },
        );
        commit('api/setClient', client, { root: true });

        commit('api/setProjectID', projectID, { root: true });

        /**
         * TODO
         * Below, the Map Editor settings are currently hardcoded.
         * Can the CMS please return the project details through PenPal
         * similar to Work Activity editor, then the below code will need
         * to be updated to use the penpal values, not these hardcoded values
         */
        if (['map'].includes(contentType)) {
            projectDetails.zoom = 14.3;
            projectDetails.center = [151.17099, -33.86514];
            projectDetails.map_style_url =
                'mapbox://styles/spatialmedia/ck88khlkd0vrc1is38iocpjci';
        }

        const dataObj = {
            contentUUID,
            contentData,
            projectDetails,
        };

        if (contentType === 'workActivity') {
            dataObj.surroundingMarkers = await dispatch(
                'api/getSurroundingMarkers',
                projectID,
                { root: true },
            );
        }

        // Set project & client details in api store module
        commit('api/setProjectID', projectID, { root: true });
        commit('api/setContentID', contentUUID, { root: true });

        const project = await dispatch('api/getProject', projectID, {
            root: true,
        });
        commit('api/setProject', project, { root: true });

        await dispatch(
            'api/getConfig',
            {
                projectId: projectID,
                clientId: projectDetails.client_id,
            },
            { root: true },
        );

        return dataObj;
    },
    async generateSlug({ dispatch, rootState }, value) {
        if (value && rootState.api.contentID) {
            const slug = slugify(value, {
                lower: true,
                strict: true,
                trim: true,
            });
            const valid = await dispatch(
                'api/checkSlugIsValid',
                {
                    slug,
                    contentID: rootState.api.contentID,
                },
                { root: true },
            );
            if (!valid) {
                return await dispatch('generateSlug', `${slug}-1`);
            }
            return slug;
        }
        if (!rootState.api.contentID) {
            console.error("Can't generate slug without contentID");
        }
        return value;
    },

    filterContent(_, data) {
        return _filterContent(data);
    },

    async diff({ dispatch, state }, { prev, next, html = true }) {
        const local =
            prev ??
            (await this.$localForage.content.getItem(state.active?.uuid)) ??
            state.active;
        const localString = JSON.stringify(
            await dispatch('filterContent', { obj: local }),
            null,
            4,
        );
        // eslint-disable-next-line camelcase
        const { metadata, updated_at, published_at } =
            (await this.$axios.$get(`/contents/${local.uuid}`))?.data ?? {};
        // eslint-disable-next-line camelcase
        const remote = next ?? { ...metadata, updated_at, published_at };
        const remoteString = JSON.stringify(
            await dispatch('filterContent', {
                obj: remote,
            }),
            null,
            4,
        );
        if (html) {
            const diff = new Diff();
            return diff.prettyHtml(diff.main(remoteString, localString));
        }
        return { remoteString, localString };
    },

    async editorSaveDraft({ commit, state, rootState, dispatch }) {
        // Set metadata to send to API
        let metadata = JSON.parse(JSON.stringify(state.active));

        // Check if portal preview url has been set in the editor
        // and if not, gets the project's portal url
        if (!isUrlValid(metadata.props.preview_url)) {
            const project = await dispatch(
                'api/getProject',
                rootState.api.projectID,
                { root: true },
            );

            metadata.props.preview_url = project.default_portal_url ?? '';
        }

        // If a work activity, adds params to make sure
        // the preview url zooms to correct WA
        if (
            metadata.name === 'WorkActivity' &&
            !metadata.props.preview_url.includes('?mapView')
        ) {
            metadata = {
                ...metadata,
                ...{
                    props: {
                        ...metadata.props,
                        preview_url: `${metadata.props.preview_url}?mapView=notifCard_${metadata.uuid}`,
                    },
                },
            };
        }

        metadata.saved_in_editor = true;

        const slug = await dispatch(
            'generateSlug',
            metadata.props?.slug ? metadata.props.slug : metadata.props?.title,
        );
        metadata.props.slug = slug;

        const contentData = {
            name: metadata.props?.title,
            slug,
            metadata,
            distribution_lists: {
                sync: metadata.props.distribution_lists,
            },
        };

        try {
            const res = await this.$axios.$patch(
                `/contents/${metadata.uuid}`,
                contentData,
            );
            // eslint-disable-next-line camelcase
            const { updated_at, published_at } = res.data;
            const activeContent = {
                ...metadata,
                // eslint-disable-next-line camelcase
                updated_at,
                // eslint-disable-next-line camelcase
                published_at,
            };
            commit('setActive', activeContent);
            commit('setPublishState', 'saved');
            commit('sendContentUpdates', res.data);

            try {
                await this.$localForage.content.removeItem(metadata.uuid);
            } catch (e) {
                console.error(e);
            }

            return {
                status: res.status,
                message: 'success',
                contentUUID: metadata.uuid,
            };
        } catch (err) {
            commit('setServerError', err);
            commit('setPublishState', 'error');
            console.log('save content err: ', err);
            return {
                status: err,
                message: 'failure',
                contentUUID: metadata.uuid,
            };
        }
    },
    // Enables/disables CMS save/publish buttons
    setToPublishable: ({ commit, state }, val = true) => {
        return new Promise((resolve, reject) => {
            commit('setToPublishable', val);

            state.parentMethods
                ?.editorIsPublishable(val)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    },
    // Directs back to the CMS table view
    finishEditing: ({ state, dispatch }) => {
        return new Promise((resolve, reject) => {
            state.parentMethods
                ?.editorFinishEditing()
                .then((res) => {
                    if (state.context !== 'invoked') {
                        dispatch('closeEditor');
                    }
                    return resolve(res);
                })
                .catch((err) => reject(err));
        });
    },
    editorClose: ({ state }) => {
        state.parentMethods?.editorClose();
    },
    editorOpen({ state }, data) {
        if (this.$config.STANDALONE) {
            window.open('/modal?id=' + data.uuid, '_blank');
        } else {
            state.parentMethods?.editorOpen(data);
        }
    },
};
