import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _32a3d577 = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _120a9c3a = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _1d9ba003 = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _3867b688 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _36bb0b8d = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _336b4d7a = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _447b1f76 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _19a23818 = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _e33fbcb0 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _087cc2a8 = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _97cdf21e = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _57811efa = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _49be3eaa = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _f90dd320 = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _2f6d8190 = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _de364c42 = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _639db314 = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _75e4a0c0 = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _9ea9192a = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _547d77f8 = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _5849afa2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _32a3d577,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _120a9c3a,
    name: "form"
  }, {
    path: "/header",
    component: _1d9ba003,
    name: "header"
  }, {
    path: "/map",
    component: _3867b688,
    name: "map"
  }, {
    path: "/modal",
    component: _36bb0b8d,
    name: "modal"
  }, {
    path: "/news-post",
    component: _336b4d7a,
    name: "news-post"
  }, {
    path: "/page",
    component: _447b1f76,
    name: "page"
  }, {
    path: "/page-template",
    component: _19a23818,
    name: "page-template"
  }, {
    path: "/preview",
    component: _e33fbcb0,
    name: "preview"
  }, {
    path: "/scene",
    component: _087cc2a8,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _97cdf21e,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _57811efa,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _49be3eaa,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _f90dd320,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _2f6d8190,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _de364c42,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _639db314,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _75e4a0c0,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _9ea9192a,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _547d77f8,
    name: "contents-id"
  }, {
    path: "/",
    component: _5849afa2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
