import { render, staticRenderFns } from "./AccordionItem.vue?vue&type=template&id=3ef66efc&scoped=true&"
import script from "./AccordionItem.vue?vue&type=script&lang=js&"
export * from "./AccordionItem.vue?vue&type=script&lang=js&"
import style0 from "./AccordionItem.vue?vue&type=style&index=0&id=3ef66efc&lang=scss&scoped=true&"
import style1 from "./AccordionItem.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef66efc",
  null
  
)

export default component.exports